import { graphql, useStaticQuery } from "gatsby"

export default function useGallery() {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
        nodes {
          id
          childImageSharp {
            gatsbyImageData(
              height: 435
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  `)

  return data.allFile.nodes.map(({ id, childImageSharp }) => ({
    ...childImageSharp,
    id,
  }))
}
