import React, { useCallback, useMemo, useState, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import Layout from "../components/Layout"
import Slider from "../components/Slider"
import Link from "../components/Link"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { limitText } from "../utils/text"
import { tabletWidth, useWindowSize } from "../utils/windowSize"
import useGallery from "../utils/useGallery"
import { handleSubmitForm } from "../utils/form"

import "../styles/home.scss"

export default function Home({ pageContext }) {
  const [activeGalleryImage, setActiveGalleryImage] = useState(null)

  const windowSize = useWindowSize()

  const gallery = useGallery()

  const locations = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("locations")).filter(
            l => l.lng === pageContext.language
          )
        : [],
    []
  )

  const upcomingTours = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("tours"))
            .concat(JSON.parse(window?.localStorage.getItem("lectures")))
            .filter(t => t.lng === pageContext.language && t.dates.length)
            .sort(
              (a, b) =>
                new Date(a.dates[0]?.time).getTime() -
                new Date(b.dates[0]?.time).getTime()
            )
            .slice(0, 3)
        : [],
    []
  )

  const recommendations = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("recommendations")).filter(
            r => r.lng === pageContext.language
          )
        : [],
    []
  )

  const clients = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("clients"))
        : [],
    []
  )

  const locationsSliderSettings = useMemo(
    () => ({
      dots: true,
      infinite: locations.length > (windowSize.width > tabletWidth ? 3 : 2),
      speed: 500,
      slidesToShow: windowSize.width > tabletWidth ? 3 : 2,
      slidesToScroll: 1,
      rtl: pageContext.language === "he",
      className: "location-slider",
      arrows: windowSize.width > tabletWidth,
    }),
    [windowSize.width]
  )

  const recommendationsSliderSettings = useMemo(
    () => ({
      dots: windowSize.width > tabletWidth,
      infinite:
        recommendations.length > (windowSize.width > tabletWidth ? 4 : 1),
      speed: 500,
      slidesToShow: windowSize.width > tabletWidth ? 4 : 1,
      slidesToScroll: 1,
      rtl: pageContext.language === "he",
      className: "recommendations-slider",
    }),
    [windowSize.width]
  )

  const clientsSliderSettings = useMemo(
    () => ({
      dots: windowSize.width > tabletWidth,
      infinite: clients.length > (windowSize.width > tabletWidth ? 5 : 1),
      speed: 500,
      slidesToShow: windowSize.width > tabletWidth ? 5 : 1,
      slidesToScroll: 1,
      rtl: pageContext.language === "he",
      className: "clients-slider",
    }),
    [windowSize.width]
  )

  const gallerySliderSettings = useMemo(
    () => ({
      dots: windowSize.width > tabletWidth,
      infinite: true,
      speed: 500,
      slidesToShow: windowSize.width > tabletWidth ? 4 : 2,
      slidesToScroll: windowSize.width > tabletWidth ? 3 : 1,
      rtl: pageContext.language === "he",
      className: "gallery-slider",
      arrows: false,
    }),
    [windowSize.width]
  )

  const getUpcoming = useCallback(
    tour =>
      windowSize.width > tabletWidth ? (
        <div
          href="/tour"
          state={{ tour }}
          className="home-upcoming-tour"
          key={tour.title}
        >
          <div className="tour-details">
            <p className="tour-title">{limitText(tour.title, 19)}</p>
            <p className="tour-subtitle">{limitText(tour.subtitle, 32)}</p>
            <p className="tour-content">{limitText(tour.content, 235)}</p>
            <p className="tour-date">
              {new Date(tour.dates[0].time).toLocaleDateString("en-GB")}
            </p>
            <Link
              href={`/${tour.location ? "tour" : "lecture"}`}
              state={{ tour }}
              className="tour-cta"
            >
              <FormattedMessage id="global_extra-details" />
            </Link>
          </div>
          <img src={tour.imgs[0]?.url} alt={tour.title} />
        </div>
      ) : (
        <Link
          href="/tour"
          state={{ tour }}
          className="home-upcoming-tour"
          key={tour.title}
        >
          <div className="tour-details">
            <p className="tour-title">{tour.title}</p>
            <p className="tour-subtitle">{limitText(tour.subtitle, 34)}</p>
            <p className="tour-date">
              {new Date(tour.dates[0]?.time).toLocaleDateString("en-GB")}
            </p>
          </div>
          <img src={tour.imgs[0]?.url} alt={tour.title} />
        </Link>
      ),
    [windowSize.width]
  )

  const handleClickOutsidePopup = useCallback(
    e =>
      !e?.target?.classList?.contains("absloute-gallery-img") &&
      setActiveGalleryImage(null),
    []
  )

  useEffect(() => {
    const body = document.querySelector("body")
    if (activeGalleryImage) {
      body.style.overflow = "hidden"
      typeof document != "undefined" &&
        document.addEventListener("mousedown", handleClickOutsidePopup, false)
    } else {
      body.style.overflow = "auto"
      typeof document != "undefined" &&
        document.removeEventListener(
          "mousedown",
          handleClickOutsidePopup,
          false
        )
    }
  }, [activeGalleryImage])

  return (
    <Layout pageContext={pageContext}>
      <div className="home">
        <div className="banner">
          <h1>
            <FormattedMessage id="home_banner-title" />
          </h1>
          <h2>
            <FormattedMessage id="home_banner-subtitle" />
          </h2>
          <form
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            name="home-page"
            id="home-page"
            onSubmit={e =>
              handleSubmitForm(e, () =>
                alert(pageContext.messages["form_success"])
              )
            }
          >
            <input type="hidden" name="form-name" value="home-page" />
            <p>
              <FormattedMessage id="home_banner-register" />
            </p>
            <div className="form-content">
              <input
                placeholder={pageContext.messages["global_name"]}
                name="name"
                required
                id="name"
              />
              <input
                placeholder={pageContext.messages["global_email"]}
                name="email"
                id="email"
                required
                pattern="(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}"
                title="email not valid"
              />
              <input
                placeholder={pageContext.messages["global_phone"]}
                name="phone"
              />
              <button type="submit">
                <FormattedMessage id="home_banner-submit" />
              </button>
            </div>
          </form>
        </div>
        <div className="home-block home-geo">
          <h3>
            <FormattedMessage id="global_tours-geo" />
          </h3>
          <Slider settings={locationsSliderSettings}>
            {locations.map(location => (
              <Link
                key={location.location}
                href={"/location"}
                state={{ location }}
                className="location-slider-container"
              >
                <img
                  className="location-slider-img"
                  src={location.img}
                  alt={location.location}
                />
                <p>{location.location}</p>
              </Link>
            ))}
          </Slider>
        </div>
        <div className="home-block home-upcoming">
          <h3>
            <FormattedMessage id="global_tours-upcoming" />
          </h3>
          {upcomingTours.map(getUpcoming)}
        </div>
        <div className="home-block home-about">
          <h3 className="about-title-mobile">
            <FormattedMessage id="home_about-title" />
          </h3>
          <StaticImage
            src="../assets/about.png"
            className="about-img"
            alt="about"
          />
          <div className="about-content">
            <h4 className="about-title">
              <FormattedMessage id="home_about-title" />
            </h4>
            <p className="about-text">
              <FormattedMessage id="home_about-content" />
            </p>
          </div>
        </div>
        <div className="home-block home-recommendations">
          <h3>
            <FormattedMessage id="home_recommendations" />
          </h3>
          <Slider settings={recommendationsSliderSettings}>
            {recommendations.map(rec => (
              <div key={rec.name} className="recommendations-slider-item">
                <p className="rec-item-name">{rec.name}</p>
                <StaticImage
                  className="starts-img"
                  src="../assets/starts.png"
                  alt="stars"
                />
                <p className="rec-item-content">{rec.recommendation}</p>
              </div>
            ))}
          </Slider>
        </div>
        <div className="home-block home-clients">
          <h3>
            <FormattedMessage id="home_clients" />
          </h3>
          <Slider settings={clientsSliderSettings}>
            {clients.map(client => (
              <img
                className="client-img"
                key={client.name}
                alt={client.name}
                src={client.img}
              />
            ))}
          </Slider>
        </div>
        <div className="home-block home-gallery">
          <h3>
            <FormattedMessage id="global_gallery" />
          </h3>
          <Slider settings={gallerySliderSettings}>
            {gallery.map(({ id, gatsbyImageData }) => (
              <span
                key={id}
                role="button"
                tabIndex={-1}
                onClick={() => setActiveGalleryImage(gatsbyImageData)}
                onKeyPress={event =>
                  event.key === "Enter" &&
                  setActiveGalleryImage(gatsbyImageData)
                }
              >
                <GatsbyImage
                  className="gallery-image"
                  key={id}
                  image={gatsbyImageData}
                  alt={id}
                />
              </span>
            ))}
          </Slider>
        </div>
        <div
          className={`absloute-gallery-img-container ${
            activeGalleryImage ? "open" : ""
          }`}
        >
          {activeGalleryImage && (
            <GatsbyImage
              image={activeGalleryImage}
              alt="gallery-open-image"
              className="absloute-gallery-img"
            />
          )}
        </div>
      </div>
    </Layout>
  )
}

export { Head } from "../components/Head"
