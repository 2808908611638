import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import SlickSlider from "react-slick"

function SlickButtonFix({ currentSlide, slideCount, children, ...props }) {
  return <span {...props}>{children}</span>
}

export default function Slider({ settings, children }) {
  // const sliderRef = useRef()
  // const rtlWA = useCallback(() => {
  //   sliderRef.current ? sliderRef.current.slickGoTo(3) : setTimeout(rtlWA, 100)
  // }, [])

  // useEffect(() => {
  //   settings.rtl && rtlWA()
  // }, [])
  return (
    <div style={{ direction: "ltr" }}>
      <SlickSlider
        // ref={sliderRef}
        {...settings}
        nextArrow={
          <SlickButtonFix>
            <StaticImage
              alt="next-arrow"
              src="../../assets/caret-down.png"
              imgStyle={{ rotate: `270deg` }}
            />
          </SlickButtonFix>
        }
        prevArrow={
          <SlickButtonFix>
            <StaticImage
              alt="prev-arrow"
              src="../../assets/caret-down.png"
              imgStyle={{ rotate: `90deg` }}
            />
          </SlickButtonFix>
        }
      >
        {children}
      </SlickSlider>
    </div>
  )
}
