export async function handleSubmitForm(e, cb) {
  e.preventDefault()
  await fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(new FormData(e.target)).toString(),
  })
    .then(cb)
    .catch(() => alert("error"))
  e.target.reset()
}
